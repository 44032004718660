<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Information">
        <template slot="actions">
          <vs-button type="line" size="small" :to="{name:`companies-edit`, params:{id: $route.params.id}}" icon-pack="feather" icon="icon-edit">Edit</vs-button>
        </template>
        <info :id="$route.params.id"></info>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Info from './sections/Info.vue'
export default {
  components:{
    Info
  }
}
</script>

<style>

</style>