<template>
  <div class="vx-col w-full">
    <table>
      <tr>
        <td class="font-semibold">Company Name</td>
        <td>{{data.name}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Area</td>
        <td>{{data.area_name}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Address</td>
        <td>{{data.address}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Phone</td>
        <td>{{data.phone}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Email Address</td>
        <td>{{data.email}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Website</td>
        <td>{{data.website}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Contact Person</td>
        <td>{{data.contact_person}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Contact Person Phone</td>
        <td>{{data.contact_person_no}}</td>
      </tr>
      <tr>
        <td class="font-semibold">Type</td>
        <td>
          <vs-chip v-if="data.is_center" color="success">Center</vs-chip>
          <vs-chip v-if="!data.is_center" color="warning">Branch</vs-chip>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props:['id'],
  data(){
    return {
      data: {}
    }
  },
  watch:{
    id(val){
      if(val) return this.getData();
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'companies/show'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.id)
      this.data = data
    }
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style lang="scss">
  table {
    td {
      vertical-align: top;
      min-width: 200px;
      padding-bottom: .8rem;
      word-break: break-all;
    }
  }
</style>